.List6{
	
	.List6__item{
		
	}
	.List6__link{
		color: $colorMain;
		@include spFz();
		
		line-height: 24px;
		text-decoration: none;
		@include hover;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		.List6__item{
			
		}
		.List6__link{
			font-size: 16px;
			line-height: 30px;
		}

    }
}