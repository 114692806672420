// breakpoint
$breakpoint1: 768px;
// $breakpoint2: 960px;
//
// // size
// $pcMinWidth: 1000px + 50px + 50px;
// $pcMaxWidth: 1344px + 50px + 50px;
//
//
// // color
$colorMain: #39a438;
$colorMain2: #c8e3c7;
// $colorSub: #0099cc;
$colorBg1: #fefefe;
$colorBg2: #444;
$colorBg3: #f0f0f0;
$colorText1: #444;
// $colorText2: #a6a6a6;
// $colorBorder1: #b5c0de;
//
//
// // icons
// $iconMap: (
// 	icon-mypage:      '\e900',
// 	icon-menu:        '\e901',
// 	icon-close:       '\e902',
// 	icon-right:       '\e903',
// 	icon-top:         '\e904',
// 	icon-plus:        '\e905',
// 	icon-blank:       '\e906',
// 	icon-blank-small: '\e907',
// 	icon-airplane:    '\e908',
// 	icon-calendar:    '\e909',
// 	icon-home2:       '\e90b',
// 	icon-home1:       '\e90a',
// 	icon-entry:       '\e90c',
// );
@mixin textShadow() {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.7), 0 0 20px rgba(0, 0, 0, 0.7);
}

@mixin textShadow2() {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
@mixin imgBg {
    //画像にかかるグレー
    
    background-color: rgba(255,255,255,.0);
}
@mixin imgBg2 {
    //画像にかかるグレー hoverの色
    background-color: rgba(255,255,255,.2);
}

@mixin spFz(){
    font-size: 14px;
}
@mixin spLh(){
    line-height: 24px;
}

@mixin hover(){
    &:hover{
        border-color: #ccc;
        color: #ccc;
    }
}