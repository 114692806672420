.SnsList{
	
	display: flex;
	justify-content: center;
	align-items: flex-end;
	.SnsList__item{
		flex: 0 0 auto;
		padding: 0 15px;
		&.-tw{
			.SnsList__link{
				width: 20px;	
			}
		}
		&.-fb{
			.SnsList__link{
				width: 11px;
			}
		}
		&.-in{
			.SnsList__link{
				width: 21px;
			}
		}
	}
	.SnsList__link{
		width: 27px;
		display: block;
		transition: 0.5s;
		&:hover{
			opacity: .7;
		}
	}
	.SnsList__img{
		width: 100%;
		vertical-align: bottom;	
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
	
		.SnsList__item{
			padding: 0 10px;
			&.-tw{
				.SnsList__link{
					width: 27px;	
				}
			}
			&.-fb{
				.SnsList__link{
					width: 16px;
				}
			}
			&.-in{
				.SnsList__link{
					width: 29px;
				}
			}
		}
		.SnsList__link{
			width: 27px;
		}
		.SnsList__img{
		}
    }
}