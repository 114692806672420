.Text2{
	
	&.-spPt0{
		//SP
		@media only screen and (max-width: $breakpoint1 - 1){
			padding: 0 0 15px;
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
    }
}