.SectionTop{
	overflow: hidden;
	.SectionTop__lead{
		text-align: center;
		font-weight: bold;
		@include spFz();
		@include spLh;
		padding-top: 10px;
	}
	.SectionTop__btn{
		padding: 15px 0 30px;
	}
	
	&.-bgGray{
		background-color: $colorBg3;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		.SectionTop__lead{
			font-size: 18px;
			padding: 15px 0 30px;
		}
		.SectionTop__btn{
			padding: 30px 0 60px;
		}
    }
}