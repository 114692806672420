/* *****************************************************************
*	コンテントエリア
* ***************************************************************** */

.LayoutInner {
margin: auto; 
max-width: 960px;
padding: 0 30px;
}

/* Tablet (Portrait) 画面の横幅が768px〜959pxまで
---------------------------------------------------- */

@media only screen and (min-width: 768px) and (max-width: 959px) {
.LayoutInner { width: 728px; padding: 0 20px; }
}

/* Mobile (Portrait) 画面の横幅が300px~767pxまで（基本）
---------------------------------------------------- */

@media only screen and (max-width: 767px) {
.LayoutInner { width: 290px; padding: 0px 0px 0px 0px; }
}

/* Mobile (Portrait/iPhone6) 画面の横幅が375px〜479pxまで
---------------------------------------------------- */

@media only screen and (min-width: 375px) and (max-width: 479px) {
.LayoutInner { width: 336px; }
}


/* Mobile (Landscape) 画面の横幅が480px〜767pxまで
---------------------------------------------------- */

@media only screen and (min-width: 480px) and (max-width: 767px) {
.LayoutInner { width: 420px; }
}

@media only screen and (max-width: 767px) {
.LayoutInner { 
    &.-spFull{
        width: auto;
    }
}
}