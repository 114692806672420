.List5{
	
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 0 -8px 10px;
	.List5__item{
		flex: 0 0 50%;
		padding: 0 8px 15px;
		box-sizing: border-box;
	}
	.List5__link{
		font-weight: bold;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		@include spFz();
		@include spLh();
		text-align: center;
		color: $colorMain;
		border: 1px solid $colorMain;
		text-decoration: none;
		min-height: 40px;
		@include hover;
		height: 100%;
	
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		margin: 0 -15px;
		.List5__item{
			flex: 0 0 25%;
			max-width: 25%;
			padding: 0 15px 30px;
		}
		.List5__link{
			height: 80px;
			font-size: 16px;
			line-height: 24px;
			border: 2px solid $colorMain;
		}

    }
}