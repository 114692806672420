/* --------------------
 * Header
 * -------------------- */
.Header{
	//some style
	

	.Header__logo{
		width: 150px;
		padding: 15px 0;
		margin: auto;
	}
	.Header__inner1{
		
	}
	.Header__inner2{
		
	}
	.Header__inner3{
		
	}
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Header__logo{
			width: 230px;
			float: left;
			padding: 0;
		}
		.Header__inner1{
			
		}
		.Header__inner2{
			padding-top: 28px;
			overflow: hidden;
		}
		.Header__inner3{
			width: 60%;
			float: right;
		}

	}
}
