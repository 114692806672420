.List7{
	
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -8px;
	.List7__item{
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 8px;
		box-sizing: border-box;
		margin: 0 0 16px;
	}
	
	.List7__dl{
		border: 1px solid $colorMain;
		height: 100%;
		margin: 0;
		background-color: $colorBg1;
	}
	.List7__ParentCat{
		
	}
	.List7__ParentCatLink{
		display: block;
		background-color: $colorMain;
		color: #fff;
		font-size: 15px;
		text-decoration: none;
		padding: 5px 9px;
		font-weight: bold;
		border-bottom: 1px solid $colorMain;
		&:hover{
			background-color: $colorBg1;
			color: $colorMain;
			border-bottom: 1px solid $colorMain;
		}
	}
	
	.List7__ChildCat{
		margin: 0;
	}
	.List7__ChildCatLink{
		color: $colorMain;
		display: block;
		@include spFz();
		text-decoration: none;
		padding: 3px 9px;
		@include hover;
	}
	&.-mt{
		margin-top: 25px;
	}
	
	//SP
    @media only screen and (max-width: $breakpoint1 - 1){
		&.-mb{
			margin: 0 -8px -16px;
		}
		&.-mt{
			margin-top: 25px;
		}
    }
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		margin: 0 -15px;
		justify-content: flex-start;
		.List7__item{
			flex: 0 0 25%;
			max-width: 25%;
			padding: 0 15px;
			margin: 0 0 30px;
		}
		
		.List7__dl{
			
		}
		.List7__ParentCat{
			
		}
		.List7__ParentCatLink{
			font-size: 18px;
			line-height: 30px;
		}
		
		.List7__ChildCat{
			&:first-of-type{
				margin-top: 5px;
			}
			&:last-of-type{
				margin-bottom: 5px;
			}
		}
		.List7__ChildCatLink{
			font-size: 16px;
			line-height: 24px;
			padding: 0 10px;
		}

    }
}