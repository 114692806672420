.Btn1{
	//some style
	text-align: center;
	.Btn1__link{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border: 2px solid $colorMain;
		color: $colorMain;
		text-decoration: none;
		font-size: 14px;
		height: 30px;
		border-radius: 15px;
		min-width: 150px;
		margin: auto;
		box-sizing: border-box;
		padding: 0 8px 0;
		letter-spacing: 0;
		&:hover{
			border-color: #ccc;
			color: #ccc;
		}
	}
	
	&.-white{
		.Btn1__link{
			border: 2px solid #fff;
			color: #fff;
			&:hover{
				border-color: $colorMain;
				background-color: $colorMain;
				color: #fff;
			}
		}
	}
	&.-beta{
		.Btn1__link{
			border: 2px solid $colorMain;
			color: #fff;
			background-color: $colorMain;
			&:hover{
				background-color: #fff;
				color: $colorMain;
			}
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		.Btn1__link{
			display: flex;
			width: 300px;
			height: 50px;
			border-radius: 25px;
			margin: auto;
			font-size: 18px;
			font-weight: bold;
			border-width: 3px;
			padding: 0;
			&.-white{
				.Btn1__link{
					border: 3px solid #fff;
				}
			}
			&.-beta{
				.Btn1__link{
					border: 3px solid $colorMain;
				}
			}
		}
    }
}