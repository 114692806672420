.NewsTabs{
	
	margin-bottom: 0;
	overflow: hidden;
	
	.NewsTabs__list{
		display: flex;
		justify-content: space-between;
		position: relative;
		margin: 0 -4px;
		&::after{
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: $colorText1;
			z-index: 1;
		}

	}
	
	.NewsTabs__item{
		flex: 1 1 auto;
		padding: 0 4px;
		&.current{
			.NewsTabs__link{
				color: $colorText1;
				border: 2px solid $colorText1;
				border-bottom: none;
				z-index: 2;
				// border-bottom: 1px solid #CCC;
				&:hover{
					color: #fff;
					background-color: $colorText1;
				}
			}
		}
	}
	.NewsTabs__link{
		font-size: 11px;
		color: #ccc;
		font-weight: bold;
		border: 2px solid #ccc;
		display: block;
		text-align: center;
		border-bottom: none;
		position: relative;
		background-color: $colorBg1;
		z-index: 0;
		padding: 3px 0;
		letter-spacing: 0;
		&:hover{
			color: #fff;
			background-color: $colorText1;
			border-color:  $colorText1;
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		
			

		.NewsTabs__list{
			margin: 0 -15px 0;
			&::after{
				
			}

		}
			
		.NewsTabs__item{
			padding: 0 15px;
			&.current{
				
			}
		}
		.NewsTabs__link{
			font-size: 18px;
			padding: 8px 0;
		}

    }
}