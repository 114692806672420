.FullBolck{
	// ブロックが３つ、コンテンツいっぱいまで並んでいるスタイル
	
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	.FullBolck__item{
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0;
		
	}
	.FullBolck__link{
		display: block;
		position: relative;
		&:hover{
				.FullBolck__figure::after{
					@include imgBg2;
				}
			}
	}
	.FullBolck__figure{
		position:relative; 
		margin-bottom: 0;
		height: 215px;
		&::after{
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			@include imgBg;
			transition: 0.5s;
		}
		
	}
	.FullBolck__img{
		
	}
	.FullBolck__title{
		@include textShadow();
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		border: none;
		color: #fff;
		margin: 0;
		width: 100%;
		font-weight: bold;
		text-align: center;
		font-size: 18px;
		padding-left: 20px;
		padding-right: 20px;
		box-sizing: border-box;
	}
	.FullBolck__wrapper{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
	}
	.FullBolck__text{
		@include textShadow();
		color: #fff;
		margin: 0;
		font-size: 15px;
		text-align: center;
	}
	
	&.-two{
		.FullBolck__item{
			flex: 1 1 100%;
		}
	}
	&.-three{
		.FullBolck__item{
			flex: 1 1 100%;
		}
	}
	&.-oneMargin{
		.FullBolck__item{
			flex: 1 1 100%;
		}
	}
	&.-full{
		display: block;
		.FullBolck__item{
			position: relative;
		}
		.FullBolck__figure{
			height: 150px;
			&::after{
				content: none;
			}
			
		}
		.FullBolck__img{
			
		}
		.FullBolck__title{
			position: static;
			text-align: center;
			transform: none;
			font-size: 18px;
			padding: 0 15px 5px;
			@include textShadow2();
			
		}
		.FullBolck__wrapper{
			
		}
		.FullBolck__text{
			padding: 0 15px 10px;
			@include textShadow2();
			color: #fff;
			margin: 0;
			@include spFz;
			@include spLh;
		}
		.FullBolck__btn{
			
		}
	}
	
	&.-mt{
		margin: 16px 0 0;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		flex-wrap: nowrap;
		.FullBolck__item{
			
		}
		.FullBolck__link{
			
		}
		.FullBolck__figure{
			
		}
		.FullBolck__img{
			
		}
		.FullBolck__title{
			font-size: 18px;
		}
		&.-two{
			.FullBolck__item{
				flex: 1 1 50%;
				max-width: 50%;//IE 用
			}
			.FullBolck__figure{
				
				height:290px;
			}
		}
		&.-three{
			.FullBolck__item{
				flex: 1 1 33.33%;
				max-width: 33.33%;//IE 用
			}
			.FullBolck__figure{
				
				height:300px;
			}
		}
		// 横に一つ。左右余白
		&.-oneMargin{
			justify-content: center;
			.FullBolck__item{
				flex: 0 0 50%;
				max-width: 50%;//IE 用
			}
			.FullBolck__figure{
				height:290px;
			}
		}
		&.-small{
			.FullBolck__figure{
				
				height:290px;
			}
		}
		&.-mt{
			margin: 30px 0 0;
		}
		&.-full{
			display: block;
			overflow: hidden;
			.FullBolck__item{
				flex: 1 1 100%;
				max-width: 100%;
			}
			.FullBolck__figure{
				height: 300px;
				&::after{
				}
				
			}
			.FullBolck__img{
				
			}
			.FullBolck__title{
				font-size: 24px;
			}
			.FullBolck__text{
				
			}
			.FullBolck__btn{
				
			}
		}
    }
}