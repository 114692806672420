.Cat{
	
	display: inline-flex;
	padding: 0 5px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	border: 1px	solid #999999;
	color: #999999;
	font-size: 11px;
	box-sizing: border-box;
	line-height: 1;
	background-color: $colorBg1;
	height: 20px;
	min-width: 90px;

	.Cat__text{
		// SP
		@media only screen and (max-width: $breakpoint1 - 1){
			// transform: scale(0.8);
			// margin: 0 -10px;
			letter-spacing: 0;	
		}
	}
	&.-list{
		width: 115px;
		padding: 0;
		
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 12px;
		height: 24px;
		padding: 0 18px;
		&.-list{
			width: 135px;
			padding: 0;	
		}
    }
}