.Contact{
	
	.Contact__item {

	}

	.Contact__itemTitle {

	}

	.Contact__item.-privacy .Contact__itemTitle {
		font-weight: normal; 
	}

	.Contact__itemTitleText {

	}

	.Contact__itemDetail {
		margin-left: 0;
	}

	.Contact__submit {

	}

	.Contact__SubmitBtnList {

	}

	.Contact__SubmitBtn {
		input:disabled {
			opacity: 0.3;
		}
		input{
			    -webkit-appearance: none;
		}
		&.-submit {

		}

		&.-prev {

		}
	}

	.Contact__require {
		color: #ff0000;
	}

	.Contact__link {
		color: #39a438;
	}

	.mw_wp_form_confirm .Contact__item {
		&.-privacy {
			display: none;
		}
	}
	//SP style
	@media only screen and (max-width: $breakpoint1 - 1){
		*{
			font-size: 16px;
			&:focus{
				    outline: none;
			}
		}
		.Contact__item {
			// background: pink;
		}

		.Contact__itemTitle {
    	
		}

		.Contact__itemTitleText {

		}

		.Contact__itemDetail {

		}

		.Contact__submit {

		}

		.Contact__SubmitBtnList {

		}

		.Contact__SubmitBtn {

			&.-submit {

			}

			&.-prev {
				margin-top: 20px;
			}
		}
		.Contact__item.-privacy .Contact__itemDetail {
			input {
				display: none;
			}

			input + .mwform-checkbox-field-text {
				padding-left: 1.8em;
				position: relative;
				&:before {
					content: '';
					width: 16px;
					height: 16px;
					border: 2px solid #ccc;
					border-radius: 2px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}

			input:checked + .mwform-checkbox-field-text {
				position: relative;
				&:after {
					content: '';
					width: 8px;
					height: 4px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(60%, -80%) rotate(-55deg);
					border-left: 2px solid #444;
					border-bottom: 2px solid #444;
				}
			}
		}
		.Contact__item {
			&.-privacy{
				.Contact__itemTitle{
					margin-bottom: 20px;
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Contact__item {
			// background: tan;
		}

		.mw_wp_form_confirm .Contact__item {
			&.-privacy {
				display: none;
			}
		}

		.Contact__itemTitle {

		}

		.Contact__itemTitleText {

		}

		.Contact__itemDetail {
			margin-top: 11px;
			width: 480px;
			input[type=text] {
				width: 100%;
				height: 36px;
			}
			&.-contact {
				width: 630px;
				textarea {
					width: 100%;
					height: 200px;
				}

			}
		}

		.Contact__item.-contact .Contact__itemDetail {
			width: 630px;
			textarea {
				width: 100%;
				height: 200px;
			}
		}

		.Contact__item.-privacy .Contact__itemDetail {
			input {
				display: none;
			}

			input + .mwform-checkbox-field-text {
				padding-left: 1.8em;
				position: relative;
				&:before {
					content: '';
					width: 16px;
					height: 16px;
					border: 2px solid #ccc;
					border-radius: 2px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}

			input:checked + .mwform-checkbox-field-text {
				position: relative;
				&:after {
					content: '';
					width: 8px;
					height: 4px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(60%, -80%) rotate(-55deg);
					border-left: 2px solid #444;
					border-bottom: 2px solid #444;
				}
			}
		}




		.Contact__submit {
			margin-top: 30px;
		}

		.Contact__SubmitBtnList {
		}

		.Contact__SubmitBtn {
			&.-submit {

			}

			&.-prev {
					margin-top: 30px;
			}
		}
	}



}