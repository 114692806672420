.MainVisual{
	position: relative;
	width: 100%;
	.MainVisual__figure{
		margin-bottom: 0;
		height: 150px;
		position: relative;
		// &::after{
		// 	content: '';
		// 	display: block;
		// 	width: 100%;
		// 	height: 100%;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	@include imgBg;
		// }
	}
	.MainVisual__figure img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.MainVisual__title{
		margin: 0;
		position: absolute;
		font-size: 26px;
		color: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		@include textShadow ;
		width: 100%;
		text-align: center;
		max-width: 80%;
		padding-left: 30px;
		padding-right: 30px;
		font-weight: bold;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		
		overflow: hidden;
		.MainVisual__figure{
			height: 300px;
		}
		.MainVisual__figure img{
			
		}
		.MainVisual__title{
			font-size: 40px;
		}

    }
}