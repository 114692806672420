/* --------------------
 * ImageOjectFit
 * -------------------- */
.ImageOjectFit{
	// Block
	object-fit: cover;
	font-family: 'object-fit: cover';
	width: 100%;
	height: 100%;
}
