// 基本・クラス名あるもの。上書きのスタイル
header,.wrapper{
    background-color: $colorBg1;
}
ul,li{
    margin: 0 0 0 0;
}
p,
li{
    color: $colorText1;
    font-size: 16px;
    line-height: 30px;
    @media only screen and (max-width: $breakpoint1 - 1){
        @include spFz();
        @include spLh();
    }
}
p a,
li a{
    color: $colorMain;
    @include hover;
}
ul{
    // margin: 1em 0;
}

.topborder {
    border-top-color: $colorMain;
}
//ディレクトリ構造が修正したための、パスの変更
.next-left {
background: url(../images/left.gif) no-repeat left; 
}
.next-right {
background: url(../images/right.gif) no-repeat right; 
}
.search button {
background: url(../images/search.png) no-repeat; 
}

/* ヘッダー
---------------------------------------------------- */
#header{
    @media only screen and (max-width: 767px) {
        height: 45px;
        position: fixed;
    }
}
#header .logo{
    text-align: left;
    font-size: 0;
    margin-right: auto;
    margin-left: auto;
}

// バーガーボタン
@media only screen and (max-width: $breakpoint1 - 1) {
    .toggle{
        top: 0;
        right: -15px;
    }
    .hamburger{
        
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
        width: 18px;
    }
    .hamburger-inner{
        
    }
    .hamburger-inner::before{
            top: -7px;
    }
    .hamburger-inner::after{
            bottom: -7px;
    }
}
// バーガーメニュー
@media only screen and (max-width: $breakpoint1 - 1) {
    .cb-header{
        // z-index: 99 !important;
    }
    #header{
        z-index: 100;
    }
    #nav{
        border-bottom:  1px solid #dedede;
        // バーガーメニューを開いた際のロゴ
        .logo{
            background-color: $colorBg1;
            border-bottom: 1px solid #ededed;
            a{
                display: block;
                width: 150px;
                padding: 15px 0 10px;
                margin: auto;
                
            }
        }
        ul{
            // margin: 45px auto 0;
            li{
                a{
                    padding: 10px 10px;
                    font-size: 13px;
                }
            }
        }
    }
}

// 全体
.wrapper.wrapper-full{
    padding-right: 0 !important;
    padding-left: 0 !important;
    max-width: 100%;
    width: auto;
    padding: 0;
}
.wrapper.wrapper-full2{
    padding-right: 0 !important;
    padding-left: 0 !important;
    max-width: 100%;
    width: auto;
    //SP
    @media only screen and (max-width: $breakpoint1 - 1){
        margin-bottom: 30px;
    }
}
.wrapper{
    padding-top: 60px;
    //SP
    @media only screen and (max-width: $breakpoint1 - 1){
        padding-top: 30px;
    }
}
/* Mobile (Portrait) 画面の横幅が300px~767pxまで（基本）
---------------------------------------------------- */
@media only screen and (max-width: 767px) {
.wrapper { width: 290px; padding: 25px 0px 0px 0px; }
}

/* Mobile (Portrait/iPhone6) 画面の横幅が375px〜479pxまで
---------------------------------------------------- */
@media only screen and (min-width: 375px) and (max-width: 479px) {
.wrapper { width: 336px; }
}

/* Mobile (Landscape) 画面の横幅が480px〜767pxまで
---------------------------------------------------- */
@media only screen and (min-width: 480px) and (max-width: 767px) {
.wrapper { width: 420px; }
}


// ヘッダーナビ
.main-navigation {
width: 960px;
margin: 0 auto 0px auto;
z-index: 9999;
padding: 0;
text-align: center;
}

.main-navigation ul {
list-style: none;
margin: auto;
}
/* Tablet (Portrait) 画面の横幅が768px〜959pxまで
---------------------------------------------------- */
@media only screen and (min-width: 768px) and (max-width: 959px) {
.main-navigation { float: none; width: 728px; margin: 0 auto 0px auto; padding: 0; }
}
/* Mobile (Portrait) 画面の横幅が300px~767pxまで（基本）
---------------------------------------------------- */
@media only screen and (max-width: 767px) {
.main-navigation { display: none; position: fixed; float: none; width: 100%; background: #fefefe; margin: 0px auto 40px auto; border-top: 1px solid #dedede; }
}

.nav-menu{
    // PC style
    li{
        a{
            color: $colorText1;
            font-size: 16px;
            &:hover{
                background-color: transparent;
            }
            &:hover,
            &:active{
                color: #ccc;
            }
        }
    }
	@media print, screen and (min-width: $breakpoint1){
        display: flex;
        flex-wrap: nowrap;
        li{
            flex: 1 1 auto;
            a{
                padding: 24px 0;
            }
        }
    }
    
    
}

// 検索
.search{
    position: relative;
    form{
        margin-bottom: 0;
    }
    fieldset{
        margin-bottom: 0;
    }
    input{
        height: 24px;
        background-color: #eee;
        border: none;
        border-radius: 12px;
        box-sizing: border-box;
        width: 100%;
        display: block;//見た目の高さ揃えるため
        padding: 0 20px;
        margin: 0;
        max-width:100%;
    }
    button{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 2;
        background-size: contain;
    }
}
.-header{
    input{
        width: calc(100% - 10px);
    }
}

/* パンくず
---------------------------------------------------- */
.Breadcrumb{
    background-color: #f0f0f0;
    padding: 5px 0;
    .breadcrumb{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        div{
            &:last-child{
                a{
                    color: $colorText1;
                }
                span{
                    color: $colorText1;
                    white-space: nowrap;
                    
                    max-width: 100% ;
                }
            }
        }
        a{
            font-size: 12px;
            color: $colorMain;
            text-decoration: none;
            @include hover;
            span{
                transition: 0.5s;
                @include hover;
            }
        }
        span{
            font-size: 12px;
            color: $colorMain;
        }
    }
}
//SP
@media only screen and (max-width: $breakpoint1 - 1){
    .Breadcrumb{
        display: none;
        .breadcrumb{
            div{
                &:last-child{
                    a{
                        pointer-events: none;
                        color: $colorText1;
                    }
                    span{
                        color: $colorText1;
                    }
                }
            }
            a{
                @include spFz();
                @include spLh();
            }
            span{
                @include spFz();
                @include spLh();
            }
        }
    }
}

/* content
---------------------------------------------------- */
.single-contents{
    padding: 0;
    &.-mb0{
        margin-bottom: 0;
    }
    //SP
    @media only screen and (max-width: $breakpoint1 - 1){
        margin-bottom: 0;
    }
}
.content th, .content td, .content th, .content td, .single-contents th, .single-contents td, .single-contents th, .single-contents td, .page-contents th, .page-contents td, .page-contents th, .page-contents td{
    //SP
    @media only screen and (max-width: $breakpoint1 - 1){
        padding: 10px;
    }
}
/* block-three
---------------------------------------------------- */

.block-three{
    .item{
        height: auto;
        margin-bottom: 30px;
    }
    .item-img{
        margin-bottom: 0;
    }
    .item-title{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        text-align: center;
        a{
            color: #fff;
            text-decoration: none;
            @include textShadow ;
        }
    }
    
    //SP
    @media only screen and (max-width: $breakpoint1 - 1){
        margin-bottom: 0;
        .item{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        a{
            font-size: 13px;
        }
    }


}

/* ページャー
---------------------------------------------------- */
.pager{
    background-color: rgba(0,0,0,0);
    color: $colorMain;
    font-size: 16px;
    font-weight: bold;
    margin: 55px 0 30px;
    .current{
        color: $colorText1;
        background-color: rgba(0,0,0,0);
    }
    .page-numbers{
        background-color: rgba(0,0,0,0);
    }
    a{
        background-color: rgba(0,0,0,0);
        text-decoration: none;
    }
    .next,
    .prev{
        opacity: 1;
        position: relative;
        &::after{
            opacity: 1;
            content: '';
            display: block;
            width: 10px;
            height: 100%;
            top: 0;
            left: 50%;
            position: absolute;
            background-image: url(../images/pager-prev.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transform: translate(-50%,0); 
            transition: 0.5s;
        }
        &:hover{
            &::after{
                opacity: 0.5;
                
            }
        }
    }
    .next{
        &::after{
            transform: rotate(180deg);        
        }
    }
}
//SP
@media only screen and (max-width: $breakpoint1 - 1){
    .pager{
        @include spFz();
        margin: 10px 0 25px;
        .current{
        }
        .page-numbers{
            padding: 0 10px;
        }
        a{
        }
        .next,
        .prev{
            &::after{
                width: 10px;
                height: 100%;
            }
        }
        .next{
            &::after{
            }
        }
        a.page-numbers, .pager .current{
            padding:0 5px;
        }
    }
}

#next{
    
    .block-two{
        a{
            color: $colorMain;
            font-size: 16px;
            text-decoration: none;
            border: none;
            @include hover;
                padding: 0px 25px;
            &:hover{
                
                border: none;
                opacity: .7;
            }
        }
    }
    .next-left{
        background: url(../images/left.png) no-repeat left;
    }
    .next-right{
        background: url(../images/right.png) no-repeat right;
    }

}
//SP
@media only screen and (max-width: $breakpoint1 - 1){
    #next{
            margin: 15px 0 0 0;
        .block-two{
            margin: 0;
            a{
                @include spFz();
		        @include spLh();
            }
        }
        .next-left{
            margin-left: 0;
            background-size: 6px;
            a{
                padding: 0 0 0 15px;
            }
        }
        .next-right{
            // text-align: left;
            margin-left: 0;
            background-size: 6px;
            a{
                padding: 0 15px 0 0;
            }
        }
    }
}

/* フッター
---------------------------------------------------- */
footer{
    overflow: hidden;
}
.footer-inner{
    padding: 60px 0px;
    max-width: 100%;
    //SP
    @media only screen and (max-width: $breakpoint1 - 1){
        width: 290px;
        padding: 27px 0px;
    }
}
#copyright{
    background-color: $colorMain;
    a{
        text-decoration: none;
    }
}
// ページTOPに戻る スタイルを上書き
#topcontrol{
    div{
        font-size: 0 !important;
        background-color: rgba(0,0,0,0) !important;
        padding: 0 !important;
        width: 50px;
        height: 50px;
        background-image: url(../images/gotop.png)  !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        transition: 0.5s;
        &:hover{
            opacity: .7;
        }
        //SP
        @media only screen and (max-width: $breakpoint1 - 1){
            width: 25px;
            height: 25px;
        }
    }
}