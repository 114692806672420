.Btn2{
	//some style
	.Btn2__link{
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		height: 30px;
		border-radius: 15px;
		font-size: 14px;		
		padding: 0 24px;
		// padding: 0 ;
		margin: 0;
	}
	
	&.-signUp{
		
		
		.Btn2__link{
			background-color: #ff5a00;
			&:hover{
			 background-color: #ccc;	
			}
		}
	}
	&.-logIn{
		
		.Btn2__link{
			background-color: #bfbfbf;
			&:hover{
			 background-color: #ccc;
			 opacity: .7;
			}
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		
		.Btn2__link{
			font-size: 14px;
			padding: 0;			
		}
    }
}
.-footer{
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		
		.Btn2__link{
			padding: 0 24px;	
		}
    }
}