.Title1{
	position: relative;
	padding-bottom: 30px;
	text-align: center;
	border: none;
	font-weight: normal;
	
	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1px){
		margin: 7px 0 10px;
		padding-bottom: 9px;
		&.-mb0{
			margin-bottom: 0;
		}
    }
	
	&:after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		position: absolute;
		left: 50%;
		bottom: 5px;
		transform: translate(-50%,0);
		background-color: $colorMain;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 40px;
		margin: 50px 0 23px;
		padding: 0 0 15px;
		&.-mb0{
			margin: 50px 0 12px;
	    	padding: 0 0 20px;
		}
		&:after{
			width: 60px;
			height: 2px;
		}
    }
}