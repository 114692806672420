.FooterUnder{
	
	background-color: #f0f0f0;
	padding: 30px 0 30px;
	.FooterUnder__logo{
		width: 75px;
		margin: 0 auto 10px;
	}
	.FooterUnder__logoImg{
		width: 100%;
	}
	.FooterUnder__lead{
		color: #333;
		line-height: 1.5;
		text-align: center;
		font-weight: bold;
		font-size: 15px;
		padding-bottom: 11px;
	}
	.FooterUnder__wrapper{

	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		padding: 65px 0 30px;
		.FooterUnder__logo{
			width: 150px;
			margin: 0 auto 10px;
		}
		.FooterUnder__logoImg{
		}
		.FooterUnder__lead{
			font-size: 18px;
			margin: 0 0 15px;
			padding-bottom: 15px;
			line-height: 1.3;
		}
		.FooterUnder__wrapper{
			
		}
    }
}