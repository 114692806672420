.FooterNav{
	

	.FooterNav__list1{	
	}
	.FooterNav__item1{
	}
	.FooterNav__list2{
		margin: 0;
	}
	.FooterNav__item2{
		
	}
	.FooterNav__link{
		text-decoration: none;
		display: block;
		line-height: 1.2;
		&.-large{
			font-weight: bold;
		}
	}
	//SP style
	@media only screen and (max-width: $breakpoint1 - 1){
		
		.FooterNav__list1{
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		.FooterNav__item1{
			flex: 0 0 50%;
			margin: 0 0 7px;
		}
		.FooterNav__list2{
			
		}
		.FooterNav__item2{
			margin: 0 0 18px;
		}
		.FooterNav__link{
			font-size: 10px;
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		
		.FooterNav__list1{
			display: flex;
			flex-wrap: wrap;
		}
		.FooterNav__item1{
			flex: 0 0 auto;
		}
		.FooterNav__list2{
			margin: 0 23px 23px 0;
		}
		.FooterNav__item2{
			margin: 0 0 16px;
		}
		.FooterNav__link{
			font-size: 12px;
			letter-spacing: 0.3px;
			&.-large{
				font-size: 14px;
			}
			&:hover{
				opacity: .7;
			}
		}
    }
}