/* --------------------
 * footer
 * -------------------- */
#footer{
	//some style
	background-color: $colorBg2;
	color: #fff;
	a{
		color: #fff;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		// background-color: #ff3;
	}
}