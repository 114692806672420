.List1{
	
	
	.List1__item{
		display: flex;
		border-bottom: 1px solid #CCC;
		padding: 12px 15px 7px;
		align-items: center;
		flex-wrap: wrap;
		&:first-child{
			border-top: 1px solid #CCC;	
		}
	}
	.List1__date{
		padding: 0 30px 0 0;
		font-weight: bold;
		color: #444444;
		order: 2;
		font-size: 12px;
	}
	.List1__catname{
		order: 1;
		margin: 0 12px 0 0;
	}
	.List1__title{
		order: 3;
		margin: 0;
		padding: 5px 0;
		border: none;
		flex: 0 0 100%;
    	line-height: 1.2;
	}
	.List1__link{
		color: $colorMain;
		text-decoration: none;
		@include spFz();
		@include spLh();
		display: block;
		&:hover{
			opacity: .7;
		}
	}
	&.-mt0{
		margin-top: 0;
	}
	&.-noBd{
		.List1__item:first-child{
			border-top: none;
		}	
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		
		.List1__item{
			border-top:none;
			padding: 20px 0;
			flex-wrap: nowrap;
		}
		.List1__date{
			order: 1;
			font-size: 16px;
		}
		.List1__catname{
			flex: 0 1 134px;
			order: 2;
		}
		.List1__title{
			// white-space: nowrap;
			// overflow: hidden;
			flex: 0 1 auto;
			padding: 0 0 0 30px;
			// flex: 0 0 auto;
		}
		.List1__link{
			font-size: 16px;
		}
		&.-mt0{
			margin-top: 0;
		}
    }
}