.Title2{
	
	border: none;
	text-align: center;
	color: $colorText1;
	
	font-size: 18px;
	&.-mt1{
	}
	&.-mb2{
	}
	&-mb3{
	}
	&.-mt4{
	}
	&.-mb5{
	}
	&.-mt6{
	}
	&.-mb7{
	}

	//SP
    @media only screen and (max-width: $breakpoint1 - 1){
		margin: 30px 0 30px;
		padding: 0 0;
		line-height: 20px;
		&.-mb3{
			margin-bottom: 10px;
		}
		&.-mt4{
			margin-top: 0;
		}
		&.-mb5{
			margin-bottom: 25px;
		}

    }
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 24px;
		margin: 0 0 60px;
		padding: 0;
		line-height: 1.3;
		&.-mt1{
			margin-top: 30px;
		}
		&.-mb2{
			margin-bottom: 30px;	
		}
		&.-mt6{
			margin-top: 50px;
		}
		&.-mb7{
			margin-bottom: 40px;
		}
    }
}