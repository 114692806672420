.Section2{
	
	overflow: hidden;
	padding: 0 0 12px;
	&.-bgGreen{
		background-color: $colorMain2;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		    padding: 25px 0 35px;
    }
}