.Section1{

	.Section1__btn{
		margin: 8px 0 30px;
	}
	.Section1__box{
		margin: 27px 0 0;
	}
	
	//sp
	@media only screen and (max-width: $breakpoint1 - 1){
		.Section1__text{
			&.-thanks{
				
				margin: 0 0 60px;
			}
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		.Section1__btn{
			margin: 55px 0 55px;
			&.-mb{
				margin-bottom: 11px;
			}
			&.-mt{
				margin-top: 24px;
			}
			&.-m1{
				margin: 0;	
			}
		}
		.Section1__text{
			&.-thanks{
				text-align: center;
				margin: 50px 0 60px;
			}
		}
		.Section1__box{
			margin: 24px 0 55px;
		}
    }
}