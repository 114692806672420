.TopMv{
	
	//カルーセルのリスト
	.TopMv__list{
		
	}
	.TopMv__item{
		position: relative;
		display: block !important;// slickのスタイルによる、不要な余白を打ち消し
	}
	.TopMv__figure{
		position: relative;
		z-index: 1;
		margin: 0;
		width: 100%;
		height: 202px;
		// &::after{
		// 	content: '';
		// 	display: block;
		// 	width: 100%;
		// 	height: 100%;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	@include imgBg;
		// }
	}
	.TopMv__img{
		width: 100%;
		height: 100%;
	}
	.TopMv__wrapper{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 2;
		width: 100%;
		
	}
	.TopMv__title{
		color: #fff;
		font-size: 26px;
		line-height: 30px;
		text-align: center;
		font-weight: bold;
		padding-top: 0;
		padding-right: 30px;
		padding-left: 30px;
		@include textShadow();
	}
	.TopMv__btn{
		
	}
	.TopMv__desc{
		background-image: url(../images/bg1.png);
		background-repeat: repeat;
		color: #fff;
		text-align: center;
		font-size: 13px;
		@include spLh();
		padding: 24px 0;
		letter-spacing: 0;
	}
	
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		//カルーセルのリスト
		.TopMv__list{
			
		}
		.TopMv__item{
		}
		.TopMv__figure{
			height: 400px;
		}
		.TopMv__img{
			
		}
		.TopMv__wrapper{
			
		}
		.TopMv__title{
			font-size: 40px;
			line-height: 1.5em;
		}
		.TopMv__btn{
			
		}
		.TopMv__desc{
			font-size: 30px;
			line-height: 48px;
			padding: 48px 0;
		}
    }
}

// slickのスタイル
.slick-slider{
	.slick-arrow{
		
	}
	.slick-dots{
		position: absolute;
		bottom: 10px;
		left: 0;
		display: flex;
		justify-content: center;
		width: 100%;
		li{
			padding: 0;
			margin: 0 10px;
		}
		.slick-active{
			
		}
		button{
			background-color: #fff;
			width: 10px;
			height: 10px;
			border: none;
			border-radius: 50%;
			font-size: 0;
			padding: 0;
			max-width: 100%;
			transition: 0.5s;
			&:hover{
				opacity: .7;
			}
		}
		
	}
}