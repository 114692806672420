.Title3{
	
	background-color: $colorMain;
	text-align: center;
	color: #fff;
	margin: 0;
	border: none;
	font-size: 16px;
	padding: 7px 0;
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 24px;
		padding: 13px 0;
    }
}