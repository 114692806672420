.List2{
	
	.List2__figure {
		position: relative;
		z-index: 1;
		a{
			position: relative;
			display: block;
			&::after{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				@include imgBg;
				z-index: 3;
				transition: 0.5s;
			}
			&:hover{
				&::after{
					@include imgBg2;
				}
			}
		}
		
	}
	.List2__title{
		z-index: 2;
	}
	//SP
    @media only screen and (max-width: $breakpoint1 - 1){
		display: flex;
		flex-wrap: wrap ;
		margin: 0;
		.List2__item{
			margin: 0;
			flex: 0 0 50%;
			padding: 0;
			box-sizing: border-box;
			
			&:nth-child(odd){
				padding-right: 8px;
				.List2__title{
					padding-right: 8px;
				}
			}
			&:nth-child(even){
				padding-left: 8px;
				.List2__title{
					padding-left: 8px;
				}
			}
			
		}
		.List2__figure{
			height: 200px;
		}
		
		&.-mb1{
			margin-bottom: 30px;
		}
		&.-layout2{
			.List2__figure{
				height: 90px;
			}
		}

    }
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		.List2__figure{
			height: 200px;
		}
    }
}