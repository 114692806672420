.Text1{
	
	font-weight: 400;
	margin: 0;
	padding: 0;
	letter-spacing: 0;
	@include spFz();
	@include spLh();
	.Text1__bold{
		font-weight: bold;
	}
	.Text1__link{
		color: $colorMain;
		text-decoration: none;
		@include hover;
	}
	.Text1__dl{
		@include spFz();
		@include spLh();
		margin: 0;
	}
	.Text1__dt{
		@include spFz();
		@include spLh();
		font-weight: normal;
		display: block;
	}
	.Text1__dd{
		@include spFz();
		@include spLh();
		// margin: 0;
	}
	
	&.-mb{
		margin-bottom: 10px;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 16px;
		line-height: 30px;
		.Text1__dl{
			font-size: 16px;
			line-height: 30px;
		}
		.Text1__dt{
			font-size: 16px;
			line-height: 30px;
			display: inline-block;
			width: 380px;
		}
		.Text1__dd{
			display: inline-block;
			font-size: 16px;
			line-height: 30px;
		}
		&.-mb{
			margin-bottom: 22px;
		}
		
		&.-pcTac{
			text-align: center;
		}
    }
}