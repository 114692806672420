@charset "UTF-8";

// @import '../../sass/vender/_normalize.scss';
// @import '../../sass/vender/_reset.scss';
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/lib/_mixin.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/lib/_vars.scss";



// webfont
// Google Roboto
// @import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700');
// Google Loto
// @import url('//fonts.googleapis.com/css?family=Lato:100,300,400,700,900');
// Google Noto Fonts
// @import url('//fonts.googleapis.com/earlyaccess/notosansjp.css');
// font-weight: 100; => Noto Sans JP Thin
// font-weight: 300; => Noto Sans JP Light
// font-weight: 400; => Noto Sans JP Regular
// font-weight: 500; => Noto Sans JP Medium
// font-weight: 600; => Noto Sans JP Bold
// font-weight: 800; => Noto Sans JP Black




/* font setting */
// Yu Gothic
// @import '../../sass/font/_yugothic.scss';
// icon font / icomoon
// @import '../../sass/font/_icon.scss';




/* default setting */
body{
	background-color: $colorBg1;
	color: $colorText1;
	// @include fontRegular();
	// 	font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	font-size: 14px;
	line-height: 1.6;
	letter-spacing: 0.05em;
	-webkit-text-size-adjust: 100%;
}
a{
	color: $colorMain;
	// text-decoration: underline;
	&:hover{
		text-decoration: none;
		color: #ccc;
	}
}
ul{
	list-style:none;
}
h1,h2,h3,h4{
	font-weight: bold;
	border: none;
	color: $colorText1;
}

// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	img{
		width: 100%;
		height: auto;
	}
	.pc{
		display: none;
	}
	.sp{
		display: block;
	}
	h1{
		font-size: 26px;
	}
	h2{
		font-size: 18px;
	}
	h3{
		font-size: 16px;
	}
	h4{
		font-size: 15px;
	}
	body{
		padding-top: 45px;
	}
}
// PC style
@media print, screen and (min-width: $breakpoint1){
	.sp{
		display: none;
	}
	.pc{
		display: block;
	}
	body{
		font-size: 16px;
		max-width: 100%;
	}
	
	h1{
		font-size: 40px;
	}
	h2{
		font-size: 24px;
	}
	h3{
		font-size: 18px;
	}
	h4{
		font-size: 16px;
	}

}



/* blocks */
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Box1.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_breadcrumb.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Btn1.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Btn2.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_BtnWrapper.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Cat.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Contact.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Date.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Footer.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_FooterNav.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_FooterUnder.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_FullBolck.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Header.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_ImageOjectFit.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_LayoutInner.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List1.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List2.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List3.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List4.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List5.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List6.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List7.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_List8.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_MainVisual.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_NewsTabs.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Section1.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Section2.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_SectionList3.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_SectionTop.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_SnsList.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_test.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Text1.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Text2.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Title1.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Title2.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Title3.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_Title4.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/blocks/_TopMv.scss";
/* style 基本のスタイルに上書きする記述のファイル */
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/style/_content.scss";
@import "/Users/inagaki/em/07_seicho/seicho/seicho/src/sni/sass/style/_style.scss";

