.Title4{
	
	border: none;
	text-align: left;
	color: $colorText1;
	line-height: 1.4;
    font-size: 16px;
	margin: 30px 0 8px 0;
	&.-mt1{
		
	}
	// 自動で生成されるｐタグのスタイルの調整
	p{
		padding: 0;
		font-size: 14px;
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 18px;
		margin: 33px 0 33px;
		padding: 0;
		&.-mt1{
			margin-top: 30px;
		}
		
		// 自動で生成されるｐタグのスタイルの調整
		p{
			padding: 0;
			font-size: 18px;
		}
    }
}