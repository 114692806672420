.List4{
	
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 auto;
	.List4__item{
		padding: 5px 0;
		flex: 0 0 50%;
		width: auto;
		margin: 0;
		box-sizing: border-box;
	}
	.List4__date{
		padding: 0;
		font-weight: bold;
		color: #ccc;
		font-size: 12px;
	}
	.List4__figure{
		// height: 77px;
		overflow: hidden;
	}
	.List4__imgLink{
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		&::after{
			transition: 0.5s;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			@include imgBg;
		}
		&:hover{
			&::after{
				@include imgBg2;
			}
		}
	}
	.List4__img{
		
	}
	.List4__title{
		margin: 0;
		padding: 0;
		border: none;
		&.item-title{
			position: static;	
		}
	}
	.List4__titleLink{
		color: $colorMain;
		text-decoration: none;
		@include spFz();
		@include spLh();
		display: block;
		@include hover;
	}
	.List4__link{
		
		text-decoration: none;
		
		&:hover{
			// opacity: .7;
		}
	}
	//SP
	@media only screen and (max-width: $breakpoint1 - 1){
		.List4__item{
			&:nth-child(odd){
				padding-right: 8px;
			}
			&:nth-child(even){
				padding-left: 8px;
			}
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		margin: 0 -15px;
		justify-content: flex-start;
		.List4__item{
			padding: 16px 15px;
			flex: 0 0 33.33%;
			max-width: 33.33%;
		}
		.List4__date{
			font-size: 12px;
		}
		.List4__figure{
			height: 200px;
		}
		.List4__imgLink{
		}
		.List4__img{
			
		}
		.List4__title{
			&.item-title{
			}
		}
		.List4__titleLink{
			font-size: 16px;
			line-height: 24px;
		}
		.List4__link{
			
			&:hover{
				opacity: .7;
			}
		}
    }
}