
/* ニュース、ラジオ、Q&A、本文内の要素のスタイル */
.content,
.single-contents,
.page-contents{
	// 本文内のブロックのスタイル
	
	// 一般
	
	p,a{
		@include spFz();
		@include spLh();
	}

	h2,
	h3,
	h4{
		border: none;
		text-align: center;
		margin: 1em 0;
		padding: 0;
	}
	h2{
		&:first-of-type{
			// margin-top: 0;
		}
		&:first-child{
			margin-top: 0;
		}
	}
	p a,
	li a{
		color: $colorMain;
		@include hover;
	}
	a{
		
	}
	ul{
		margin: 1em 0;
	}
	br{
		display: block;
	}
	
	table{
		
	}
	th, td, th, td{
		border-right: none;
		border-left: none;
		@include spFz();
		@include spLh();
	}
	
	td,
	th{
		&:first-child{
			white-space: nowrap;
		}
	}
	.has-fixed-layout{
		td,
		th{
			&:first-child{
				white-space: normal;
			}
		}
	}
	@at-root{
		.content th, .content td, .content th, .content td, .single-contents th, .single-contents td, .single-contents th, .single-contents td, .page-contents th, .page-contents td, .page-contents th, .page-contents td{
			border-right: none;
			border-left: none;
			@include spFz();
			@include spLh();
			background-color: $colorBg1;
		}
	}
	//sp
	@media only screen and (max-width: $breakpoint1 - 1){
		margin-bottom: 30px;
	}
	//sp
	@media only screen and (max-width: $breakpoint1 - 1){
		iframe{
			max-height: 66.66vw;
		}
	}
	
	// ボタン
	.wp-block-button__link{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border: 2px solid $colorMain;
		background-color: $colorMain;
		color: #fff;
		text-decoration: none;
		font-size: 11px;
		height: 24px;
		border-radius: 12px;
		min-width: 150px;
		margin: auto;
		box-sizing: border-box;
		padding: 0 8px 0;
		letter-spacing: 0;
		&:hover{
			background-color: #fff;
			color: $colorMain;
		}
	}
	// youtube
	.wp-block-embed__wrapper{
		position: relative;
		padding-top: 56.25%;
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	
	// news
	
	.content__newsIntro{
		color: $colorText1;
	}
	.content__newsLink{
		a{
			color: $colorMain;
			@include hover;
		}
	}
	
	// base
	
	.content__baseAddress{
		padding: 0;
	}
	.content__baseContentTitle{
		//sp
		@media only screen and (max-width: $breakpoint1 - 1){
			font-size: 15px;
			padding: 0;
			margin: 25px 0;
		}
	}
	.content__baseAccessMap{
		padding-top: 56.25%;
		position: relative;
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.content__baseHow{
		strong{
			
		}
	}
	
	// radio
	.content__radioWrapper{
		overflow: hidden;
		clear: both;
	}
	.content__radioContentTitle{
		color: $colorText1;
		@include spFz();
		@include spLh();
		margin: 0 0 0;
		padding: 5px 0 2px;
	}
	.content__radioBookImage{
		max-width: 90px;
		margin: 0;
		figure{
			img{
				
			}
		}
	}
	.content__radioBookTitle{
		color: $colorMain;
		padding: 0;
	}
	.content__radioBookAuthor{
		padding: 0;
	}
	.content__radioBookLink{
		padding: 0;
		a{
			padding: 0;
			@include hover;
		}
	}
	
	// Q&A
	.content__qaQuestion{
		
	}
	.content__qaAnswer{
		border: 3px solid $colorMain2;
		padding: 12px 15px;	
		strong{
			
		}
	}
	
	// テーブル SPで縦に表示
	.tableList1{
		//sp
		@media only screen and (max-width: $breakpoint1 - 1){
			background-color: red;
			th{
				display: none;
			}
			tr{
				display: block;
				border-top: 1px solid #CCC;
				&:first-child{
					border-top: none;
				}
				&:last-child{
					border-bottom: 1px solid #CCC;
				}
			}
			tbody,
			thead{
				display: block;
			}
			td{
				display: block;
				border: none;
				padding: 0 0 0 9px;
				@include spFz;
				line-height: 1.2;
				padding-bottom: 8px;
				&:first-child{
					padding-top: 10px;
				}
				&:last-child{
					padding-bottom: 8px;
				}
				a{
					@include spFz;
					line-height: 1.2;
				}
			}
			
		}
	}
	
	
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		// 一般
		
		p,a{
			font-size: 16px;
			line-height: 30px;
		}
		h2{
			
		}
		p a{
			color: $colorMain;
		}
		th, td, th, td{
			font-size: 16px;
			line-height: 30px;
			border-color: #ccc;
		}
		@at-root{
			.content th, .content td, .content th, .content td, .single-contents th, .single-contents td, .single-contents th, .single-contents td, .page-contents th, .page-contents td, .page-contents th, .page-contents td{
				font-size: 16px;
				line-height: 30px;
				border-color: #ccc;
			}
		}

		.wp-block-button__link{
			display: flex;
			width: 300px;
			height: 50px;
			border-radius: 25px;
			margin: auto;
			font-size: 18px;
			font-weight: bold;
			border-width: 3px;
			padding: 0;
			&:hover{
				background-color: #fff;
				color: $colorMain;
			}
		}
		
		// news
		
		.content__newsIntro{
			
		}
		.content__newsLink{
			font-size: 16px;
			line-height: 30px;
			a{
				
			}
		}
		
		// base
		
		.content__baseAddress{
			font-size: 16px;
			line-height: 30px;
		}
		.content__baseContentTitle{
			font-size: 22px;
			    margin-bottom: 40px;
		}
		.content__baseAccessMap{
			padding-top:  540px;
			margin: 0 0 7px;
		}
		.content__baseHow{
			strong{
				font-size: 18px;
			}
			font-size: 16px;
		}
		
		// radio
		.content__radioContentTitle{
			font-size: 18px;
			margin: 40px 0px 20px 0px;
		    padding: 18px 0px 15px 0px;
			line-height: 130%;
		}
		.content__radioBookImage{
			max-width: 150px;
			figure{
				img{
					
				}
			}
		}
		.content__radioBookTitle{
			padding: 0;
			color: $colorMain;
		}
		.content__radioBookAuthor{
			padding: 0;
		}
		.content__radioBookLink{
			padding: 0;
			a{
				
			}
		}
		
		
		// Q&A
		.content__qaQuestion{
			font-size: 16px;
			line-height: 30px;
		}
		.content__qaAnswer{
			border: 5px solid $colorMain2;
			padding: 23px 30px;
			margin: 10px 0 0;
			strong{
				
			}
		}

    }
}

/* 固定ページ 拠点にかかるスタイル 拠点のページは、ギャラリーを仕様すると下記のスタイルが当たる */
.page-base-content{
	
	.Content_baseTitle,
	.Content__baseTitle{
		border: none;
		text-align: center;
		color: $colorText1;
		font-size: 15px;
		margin: 0 0 30px;
		padding: 0 0;
		line-height: 20px;
	}
	// ギャラリー ul
	.Content__baseImages{
		margin-bottom: 30px;
		.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item{

		}
		.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item{
			 width: calc((100% - 16px)/2);
			 margin-right: 0 ;
		}
		.wp-block-gallery .blocks-gallery-image:nth-of-type(2n), .wp-block-gallery .blocks-gallery-item:nth-of-type(2n){
			 margin-right: 0 ;
		}
		li{
			transition: 0.5s;
			flex: 0 0 calc((100% - 16px)/2);
			    width: calc((100% - 16px)/2);
				margin-right: 0;
				@at-root{
					
				}
			&:hover{
				// opacity: .7;
			}
			figure{
				position: relative;
				z-index: 1;
				height: 105px;
				// height: 29vw;
				overflow: hidden;
				img{
					
				}
				figcaption{
					background: none;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding: 0;
					margin: 0;
					z-index: 2;
					a{
						display: flex;
						position: absolute;
						z-index: 3;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						font-size: 13px;
						color: #fff;
						justify-content: center;
						align-items: center;
						font-style: normal;
						font-weight: bold;
						@include textShadow;
						padding-left: 15px;
						padding-right: 15px;
						box-sizing: border-box;
						
						&::after{
							content: '';
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: -1;
							@include imgBg;
							transition: 0.5s;
						}
						&:hover{
							&::after{
								@include imgBg2;
							}
						}
					}
				}
			}
		}
	}
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Content_baseTitle,
		.Content__baseTitle{
			font-size: 24px;
			margin: 0 0 60px;
			padding: 0;
			line-height: 1.3;
		}
		// ギャラリー ul
		.Content__baseImages{
			margin-bottom: 60px;
			justify-content: flex-start;
			align-items: flex-start;
			li{
				// flex: 0 0 calc((100% - 64px)/3);
				flex-grow: 0;
				flex-shrink: 1;
				flex-basis: calc((100% - 65px)/3);
				max-width: calc((100% - 65px)/3);
				margin-bottom: 32px;
				&:hover{
				}
				figure{
					height: 200px;
					img{
						width: 100%;
						height: 100%;
					}
					figcaption{
						a{
							font-size: 18px;
							padding-left: 15px;
							padding-right: 15px;
						}
					}
				}
				&:last-child,
				&:nth-last-child(2),
				&:nth-last-child(3){
					margin: 0 0 auto;
				}
				@at-root{
					.page-base-content{
						.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item{
							width: calc((100% - 64px)/3);
							margin-right: 32px;
						}

					}

				}
			}
			
		}
	}
	
	
		
	
	/* PC 画面の横幅が960px以上
	---------------------------------------------------- */

	@media only screen and (min-width: 960px) {
		.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item{

		}
		.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item{
			 width: calc((100% - 16px)/2);
			 // margin-right: 0 ;
		}
		.wp-block-gallery .blocks-gallery-image:nth-of-type(2n), .wp-block-gallery .blocks-gallery-item:nth-of-type(2n){
			 // margin-right: 0 ;
		}
	}



	/* Tablet (Portrait) 画面の横幅が768px〜959pxまで
	---------------------------------------------------- */

	@media only screen and (min-width: 768px) and (max-width: 959px) {

	}


	/* Mobile (Portrait) 画面の横幅が300px~767pxまで（基本）
	---------------------------------------------------- */

	@media only screen and (max-width: 767px) {
		.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item{

		}
		.wp-block-gallery.columns-3 .blocks-gallery-image,
		.wp-block-gallery.columns-3 .blocks-gallery-item{
			 width: calc((100% - 16px)/2);
			 margin-right: 0 !important;
		}
		.wp-block-gallery .blocks-gallery-item:nth-of-type(2n){
			 margin-right: 0 ;
		} 
		.wp-block-gallery .blocks-gallery-item:nth-of-type(odd){
			 margin-right: 16px !important;
		}

	}


	/* Mobile (Portrait/iPhone6) 画面の横幅が375px〜479pxまで
	---------------------------------------------------- */

	@media only screen and (min-width: 375px) and (max-width: 479px) {

	}


	/* Mobile (Landscape) 画面の横幅が480px〜767pxまで
	---------------------------------------------------- */

	@media only screen and (min-width: 480px) and (max-width: 767px) {

	}
	
}

.single-radio {
	
	// radio詳細にかけるスタイル
	.content{
		overflow: hidden;
	}
}

// タイトルの中だけ、改行
.titleBr{
	display: none !important;
}
.-pageBaseChild{
	.titleBr{
		display: block !important;
	}
}





