.List3{
	
	border-top: 1px solid #CCC;
	.List3__item{
		border-bottom: 1px solid #CCC;
    	padding: 5px 0 5px 9px;
	}
	.List3__element{
		color: #444444;
		box-sizing: border-box;
		&.-date{
			font-weight: bold;
			color: #444444;
		}
		&.-term{
			
		}
		&.-title{
			margin: 0;
			padding: 0;
			border: none;
		}
		&.-text{
			letter-spacing: 0;
			line-height: 1.2;
		}
		// 要素が三分割の子要素
		&.-third{
			
		}
	}
	.List3__link{
		color: $colorMain;
		text-decoration: none;
		@include hover;
	}
	.List3__text{
		
	}
	
	// 一覧見出し
	.List3__item{
		&.-head{
			display: none;
			.List3__element{
				
			}
		}
	}
	//SP
	@media only screen and (max-width: $breakpoint1 - 1){
		.List3__element{
			@include spFz();
			&.-date{
			}
			&.-term{
				
			}
			&.-title{
			}
			&.-text{
				padding-bottom: 5px;
			}
			// 要素が三分割の子要素
			&.-third{
			}
		}
	}
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		.List3__item{
			display: flex;
			padding: 16px 0;
			align-items: center;
		}
		
		.List3__element{
			color: #444444;
			padding: 0 0 0 20px;
			&.-date{
				flex: 0 0 130px;
			}
			&.-term{
				flex: 0 0 275px;
			}
			&.-title{
				flex: 0 0 310px;
				
				font-size: 16px;
				padding: 0 0 0 30px;
			}
			&.-text{
				flex: 1 1 auto;
			}
			// 要素が三分割の子要素
			&.-third{
				flex: 1 1 33.33%;
				max-width: 33.33%;
			}
		}
		
		// 一覧見出し
		.List3__item{
			&.-head{
				display: flex;
				font-weight: bold;
				.List3__element{
					
				}
			}
		}
    }
}