/* --------------------
 * BtnWrapper
 * -------------------- */

.BtnWrapper{
	.BtnWrapper__search{
		
	}
	.BtnWrapper__btn{
	
	}
	.BtnWrapper__text{
	
	}
	.BtnWrapper__signUp{
		
	}
	.BtnWrapper__logIn{
		
	}
	
	&.-hamburger{
	    margin: 16px 15px 15px;	
		.BtnWrapper__search{
			margin: 0;
		}
		.BtnWrapper__btn{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		.BtnWrapper__text{
			flex: 0 0 100%;
			max-width: 100%;
			@include spFz();
			line-height: 1;
		}
		.BtnWrapper__signUp{
			flex: 0 0 auto;
			max-width: 50%;
			padding: 0 8px 0 0;
			box-sizing: border-box;

		}
		.BtnWrapper__logIn{
			flex: 0 0 auto;
			max-width: 50%;
			padding: 0 0 0 8px;
    		box-sizing: border-box;
			
		}
		.BtnWrapper__sns{
			
		}
	}
	
	&.-footer{
		margin-left: auto;
		margin-right: auto;
		.BtnWrapper__search{
			margin: 0;
		}
		.BtnWrapper__btn{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0 0 30px;
		}
		.BtnWrapper__text{
			flex: 0 0 100%;
			max-width: 100%;
			color: #fff;
			@include spFz();
			margin: 0;
			text-align: center;
			padding: 9px 0;
		}
		.BtnWrapper__signUp{
			flex: 0 0 auto;
			max-width: 50%;
			padding: 0 8px 0 0;
			box-sizing: border-box;

		}
		.BtnWrapper__logIn{
			flex: 0 0 auto;
			max-width: 50%;
			padding: 0 0 0 8px;
    		box-sizing: border-box;
			
		}
		.BtnWrapper__sns{
			
		}
	}
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		

		.BtnWrapper__search{
		}
		.BtnWrapper__btn{
		}
		.BtnWrapper__text{
			margin: 0;
			padding: 0;
			text-align: center;
		}
		.BtnWrapper__signUp{
		}
		.BtnWrapper__logIn{
		}
		
		&.-header{
			display: flex;

			.BtnWrapper__search{
				flex: 1 1 50%;
				max-width: 50%;
			}
			.BtnWrapper__btn{
				flex: 1 1 50%;
				margin-top: -28px;
				font-size: 0;
			}
			.BtnWrapper__text{
				font-size: 12px;
				line-height: 1;
			    margin: 8px 0;
			}
			.BtnWrapper__signUp{
				width: 49%;
				display: inline-block;
				padding-right: 5px;
				box-sizing: border-box;
			}
			.BtnWrapper__logIn{
				width: 49%;
				display: inline-block;
				padding-left: 5px;
				box-sizing: border-box;
			}
		}
		&.-footer{
			max-width: 280px;
			.BtnWrapper__search{
				margin: 0 0 25px;
			}
			.BtnWrapper__btn{
				margin: 0 0 30px;
			}
			.BtnWrapper__text{
				font-size: 12px;
				margin: 0 0 3px;
			}
			.BtnWrapper__signUp{
				padding: 0 5px 0 0;
			}
			.BtnWrapper__logIn{
				padding: 0 0 0 5px;
			}
			.BtnWrapper__sns{
				
			}
		}
	}
}