.Date{
	display: inline-block;
	padding: 0 5px;
	box-sizing: border-box;
	color: #999999;
	font-size: 12px;

    // PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 16px;
		padding: 0 18px;
    }
}