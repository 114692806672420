.List8{
	
	
	//SP
	margin: 0;
	.List8__item{
		margin: 0 0 13px;
		padding: 0;
		box-sizing: border-box;
	}
	.List8__link{
		position: relative;	
		display: block;
		&:hover{
				.List8__figure::after{
					@include imgBg2;
				}
		}
	}
	.List8__figure{
		height: 200px;
		position: relative;
		margin: 0;
		padding: 0;
		&::after{
			transition: 0.5s;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			@include imgBg;
		}
		
	}
	.List8__img{
		
	}
	.List8__title{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		color: #fff;
		margin: 0;
		padding: 0;
		width: 100%;
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.4;
		@include textShadow ;
		padding-left: 20px;
		padding-right: 20px;
		box-sizing: border-box;
	}
	.List8__text{
		font-size: 13px;
		line-height: 16px;
		color: #333;
		padding: 7px 0 0;
	}
	
	&.-center{
		justify-content: center;
	}
	&.-mt{
		    margin-top: 15px;
	}
	
    // PC style
	@media print, screen and (min-width: $breakpoint1){
		
		display: flex;
		flex-wrap: wrap ;
		margin: 0 -15px;
		.List8__item{
			flex: 0 0 33.33%;
			max-width: 33.33%;
			padding: 0 15px;
			margin: 0 0 30px;
		}
		.List8__link{
		}
		.List8__figure{
		}
		.List8__img{
			
		}
		.List8__title{
			font-size: 18px;
			line-height: 30px;
		}
		.List8__text{
			font-size: 16px;
			line-height: 24px;
			padding: 20px 0 0;
		}
		
		&.-center{
			.List8__item{
				flex: 0 0 299px;
				max-width: 299px;
				box-sizing: content-box;
			}
		}
		&.-mt2{
			margin-top: 30px;
		}
    }
}